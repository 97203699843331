<template>
    <div>
        <div class="topimage">
            <el-image style="width:100%" :src="require('@/assets/scheme/banner.jpg')"></el-image>
        </div>
        <div style="background: #FAFAFA;padding-bottom:2%">
            <div class="appmain">
                <div class="topmain">
                    <el-row>
                        <el-col :span="12" :xs="24">
                            <div class="image">
                                <el-image v-if="info.image" :src="info.image" style="width:100%"></el-image>
                            </div>
                        </el-col>
                        <el-col :span="12" :xs="24">
                            <div class="productinfo">
                                <div class="productname">
                                    {{info.scheme_title}}
                                </div>
                                <div class="detail">
                                    {{info.scheme_desc_text}}
                                </div>
                                <div class="fankui" @click="xiazai" v-if="info.scheme_file">
                                    {{$t('scheme.planToDownload')}}
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <div style="background:#fff;margin-top:1%">
            <el-row>
                <el-col :span="24">
                    <div class="menuslist">
                        <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick">
                            <el-tab-pane :label="$t('scheme.solutionOverview')" name="first">
                                <div v-html="info.scheme_desc" class="solutionOver" :style="{'width':getImageWidth()}"></div>
                                <div class="pinglun" v-if="this.$i18n.locale != 'zh'">
                                    <div class="pltitle">{{$t('pinglun.fabiaopinglun')}}</div>
                                    <div class="plinput">
                                        <el-input type="textarea" :rows="5" :placeholder="$t('pinglun.shuodianshenme')" v-model="pingluncontent"></el-input>
                                        <div style="display: flex;justify-content: flex-end;"><div class="plbtn" @click="pinglunbtn">{{$t('pinglun.pinglun')}}</div></div>
                                    </div>
                                    <div class="pllist">
                                        <div class="allpltitle">{{$t('pinglun.quanbupinglun')}}<span class="plnum">{{pinglunlist.length}}</span></div>
                                        <div v-for="(item,index) in pinglunlist" :key="index">
                                            <div class="plitem">
                                                <div class="plimage">
                                                    <el-image style="width: 50px;height: 50px;border-radius: 50%;" :src="item.user_image"></el-image>
                                                </div>
                                                <div class="plright">
                                                    <div class="plname">{{item.user_name}}</div>
                                                    <div class="plcontent">{{item.content}}</div>
                                                    <div class="ploption">
                                                        <div class="pldate">{{item.addtime}}</div>
                                                        <div class="plicon" style="display:flex">
                                                            <div @click="huifu(item)" style="margin-top:2px;">
                                                                <el-image :src="require('@/assets/huifu.png')"></el-image>
                                                            </div>
                                                            <div style="margin-left:25px;margin-top:2px;" @click="dianzan1(item)">
                                                                <el-image :src="item.like ? require('@/assets/dianzan_active.png') : require('@/assets/dianzan.png')" ></el-image>
                                                            </div><span style="margin-left:5px" :style="{color:item.like ? '#FAD20C' : ''}">{{item.like_num}}</span>
                                                        </div>
                                                    </div>
                                                    <div class="huifu" v-if="item.textarea">
                                                        <el-input type="textarea" :rows="2" :placeholder="$t('pinglun.huifu')+hfname" v-model="huifucontent"></el-input>
                                                        <div style="display: flex;justify-content: flex-end;"><div class="hfbtn" @click="huifubtn(item.id,2,item.id)">{{$t('pinglun.huifu')}}</div></div>
                                                    </div>
                                                    <div v-for="(items,indexs) in item.get_reply" :key="indexs" v-show="item.allopen || indexs<3">
                                                        <div class="plitem">
                                                            <div class="plimage">
                                                                <el-image style="width: 50px;height: 50px;border-radius: 50%;" :src="items.user_image"></el-image>
                                                            </div>
                                                            <div class="plright">
                                                                <div class="plname">{{items.user_name}} <span v-if="items.form_name">->{{items.form_name}}</span></div>
                                                                <div class="plcontent">{{items.content}}</div>
                                                                <div class="ploption">
                                                                    <div class="pldate">{{item.addtime}}</div>
                                                                    <div class="plicon" style="display:flex">
                                                                        <div @click="huifu(items)" style="margin-top:2px;">
                                                                            <el-image :src="require('@/assets/huifu.png')" ></el-image>
                                                                        </div>
                                                                        <div style="margin-left:25px;margin-top:2px;" @click="dianzan2(items)">
                                                                            <el-image :src="items.like ? require('@/assets/dianzan_active.png') : require('@/assets/dianzan.png')" ></el-image>
                                                                        </div><span style="margin-left:5px" :style="{color:items.like ? '#FAD20C' : ''}">{{items.like_num}}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="huifu" v-if="items.textarea">
                                                            <el-input type="textarea" :rows="2" :placeholder="$t('pinglun.huifu')+hfname" v-model="huifucontent"></el-input>
                                                            <div style="display: flex;justify-content: flex-end;"><div class="hfbtn" @click="huifubtn(item.id,1,items.id)">{{$t('pinglun.huifu')}}</div></div>
                                                        </div>
                                                    </div>
                                                    <div style="font-size: 14px;color: #4E4D47;margin-top:15px" v-if="!item.allopen && item.get_reply.length>3">共{{item.get_reply.length}}条评论，<span style="color: #0052D9;cursor:pointer" @click="showall(item)">点击查看</span></div>
                                                </div>
                                            </div>
                                            <el-divider></el-divider>
                                        </div>
                                    </div>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('scheme.shipin')" name="third" v-if="shipin.length>0">
                                <div style="width:80%;margin:0 auto">
                                    <el-row :gutter="50">
                                        <el-col :span="6" :xs="24" v-for="(item,index) in shipin" :key="index">
                                            <div style="cursor:pointer" @click="shipinbofang(item.vid)">
                                                <div style="width:100%;margin:0 auto">
                                                    <el-image style="width:100%" :src="item.img"></el-image>
                                                </div>
                                                <div style="text-align: center;">{{item.title}}</div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('scheme.relatedCases')" name="anli" v-if="project.length>0">
                                <div class="anli">
                                    <el-row :gutter="40">
                                        <el-col :span="8" :xs="24" v-for="(item,index) in project" :key="index">
                                            <el-image class="alimage" :src="item.project_img"></el-image>
                                            <div class="alinfo">
                                                <div class="altitle">{{item.project_title}}</div>
                                                <div class="aldate">{{item.add_time}}</div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-tab-pane>
                            <el-tab-pane :label="$t('scheme.relatedProducts')" name="fangan" v-if="products.length>0">
                                <div class="jiejue">
                                    <el-row :gutter="40">
                                        <el-col :span="6" :xs="24" v-for="(item,index) in products" :key="index">
                                            <div style="cursor:pointer;height:260px" @click="nav('product/'+item.product_id)">
                                                <div class="jjimage">
                                                    <el-image  :src="item.image"></el-image>
                                                </div>
                                                <div class="jjtitle">{{item.product_name}}</div>
                                                <div style="text-align: center;margin-top:2%;color:rgb(51,51,51,0.6);font-size: 12px;">{{item.product_feature}}</div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                            </el-tab-pane>
                        </el-tabs>
                    </div>
                </el-col>
            </el-row>
        </div>
    </div>
</template>
<script>
import { schemeInfo,commentList,commentReplyAdd,commentAdd,commentLike,commentReplyLike } from "@/api/api"; 
export default {
    data(){
        return{
            tabindex:0,
            activeName: 'first',
            html:'<p style="text-align: center;"><img title="1476283536950176.jpeg" alt="10122139220parallel_solution_1.Jpeg" src="http://192.168.0.21/yun/data/upload/image/20161012/1476283536950176.jpeg"/><img title="1476283546528669.jpeg" alt="10122139220parallel_solution_2.Jpeg" src="http://192.168.0.21/yun/data/upload/image/20161012/1476283546528669.jpeg"/><img title="1476283582802881.jpeg" alt="10122139220parallel_solution_3.Jpeg" src="http://192.168.0.21/yun/data/upload/image/20161012/1476283582802881.jpeg"/><img title="1476283602928241.jpeg" alt="10122139220parallel_solution_4.Jpeg" src="http://192.168.0.21/yun/data/upload/image/20161012/1476283602928241.jpeg"/><img title="1476283617125033.jpeg" alt="10122139220parallel_solution_5.Jpeg" src="http://192.168.0.21/yun/data/upload/image/20161012/1476283617125033.jpeg"/><img title="1476283633534099.jpeg" alt="10122139220parallel_solution_6.Jpeg" src="http://192.168.0.21/yun/data/upload/image/20161012/1476283633534099.jpeg"/><img title="1476283663930200.jpeg" alt="10122139220parallel_solution_7.Jpeg" src="http://192.168.0.21/yun/data/upload/image/20161012/1476283663930200.jpeg"/></p>',
            schemeid:'',
            info:{},
            products:[],
            project:[],
            shipin:[],
            title:'',
            pingluncontent:'',
            huifucontent:'',
            pinglunlist:[],
            pingluntotal:0,
            hfname:'',
            userinfo:{}
        }
    },
    metaInfo () {
        return {
        title: this.title,
        meta: [
            {
                name: 'keyWords',
                content: this.$t('seo.indexkeywords')
            },
            {
                name: 'description',
                content: this.$t('seo.indexdescription')
            }
        ]
        }
    },
    created(){
        this.userinfo = sessionStorage.getItem('userinfo') ? sessionStorage.getItem('userinfo') : ''
        if(this.userinfo != ''){
            this.userinfo = JSON.parse(this.userinfo)
        }
        let schemeid  = this.$route.params.id
        this.schemeid = schemeid
        this.getInfo()
        this.getpinglun()
    },
    beforeCreate() {
        document.querySelector('body').setAttribute('style', 'background:#ffffff')
    },
    // 在销毁前，将body的style属性删除
    deactivated() {
        document.querySelector('body').removeAttribute('style')
    },
    methods:{
        async getInfo(){
            const {data:res} = await schemeInfo(this.schemeid)
            if(res.products.length>0){
                res.products.forEach((item,index)=>{
                   item.image = process.env.VUE_APP_BASE_API+item.get_image_list[0].product_img
                   delete item.get_image_list;
                })
            }
            this.products = res.products
            res.project.forEach((item)=>{
                item.project_img = process.env.VUE_APP_BASE_API+ item.project_img
            })
            res.shipin.forEach((item)=>{
                item.img = process.env.VUE_APP_BASE_API+item.img
            })
            this.shipin = res.shipin
            this.project = res.project
            res.info.image = process.env.VUE_APP_BASE_API+ res.info.scheme_image
            this.info = res.info
            this.title = res.info.scheme_title+'-'+this.$t('seo.indextitle')
        },
        xiazai(){
            let url = process.env.VUE_APP_BASE_API+this.info.scheme_file
            window.open(url,'_blank')
        },
        getImageWidth() {
            const parser = new DOMParser();
            const doc = parser.parseFromString(this.info.scheme_desc, "text/html");
            const img = doc.querySelector("img");
            let width = 1440
            if (img) {
                const imgWidth = img.getAttribute("width");
                width = imgWidth
            }
            return width+'px'
        },
        handleClick(tab, event) {

        },
        nav(item){
            this.$router.push('/'+item,'_blank')
        },
        shipinbofang(vid){
            window.open('https://v.qq.com/x/page/'+vid+'.html')
        },
        async getpinglun(){
            let user_id = ''
            if(this.userinfo != ''){
                user_id = this.userinfo.userid
            }
            const {data:res} = await commentList({topic_id:this.schemeid,topic_type:3,user_id:user_id})
            let data = this.initdata(res.list)
            this.pinglunlist = data
            this.pingluntotal = res.total
        },
        showall(item){
            this.pinglunlist = this.initdata(this.pinglunlist)
            item.allopen = true
        },
        initdata(data){
            data.forEach((item,index)=>{
                item.textarea = false
                item.allopen = false
                if(item.get_reply.length>0){
                    item.get_reply.forEach((items,indexs)=>{
                        items.textarea = false
                    })
                }
            })
            return data
        },
        jiaoyan(){
            this.$confirm(this.$t('loginerror'), this.$t('notLogIn'), {
            confirmButtonText: this.$t('logIn'),
            cancelButtonText: this.$t('close'),
            customClass: 'myConfirm',
            type: 'warning'
            }).then(() => {
                this.$router.push('/login')
            }).catch(() => {
            });
        },
        huifu(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }else{
                this.huifucontent  = ''
                this.hfname = item.user_name
                this.pinglunlist = this.initdata(this.pinglunlist)
                item.textarea = true
            }
        },

        async pinglunbtn(){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }else{
                let data = {
                    topic_id:this.schemeid,
                    topic_type:3,
                    content:this.pingluncontent,
                    user_name:this.userinfo.name,
                    user_id:this.userinfo.userid,
                    user_image:this.userinfo.photo
                }
                const {data:res} = await commentAdd(data)
                this.pingluncontent = ''
                this.$message.success('评论成功');
                this.getpinglun()
            }
        },
        async huifubtn(comment_id,reply_type,reply_id){
            let data = {
                comment_id:comment_id,
                reply_type:reply_type,
                reply_id:reply_id,
                content:this.huifucontent,
                user_name:this.userinfo.name,
                user_id:this.userinfo.userid,
                user_image:this.userinfo.photo
            }
            const {data:res} = await commentReplyAdd(data)
            this.$message.success('回复成功');
            this.getpinglun()
        },
        async dianzan1(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }
            let data ={
                id:item.id,
                user_id:this.userinfo.userid
            }
            await commentLike(data)
            item.like = true
            item.like_num = item.like_num+1
        },
        async dianzan2(item){
            if(this.userinfo == ''){
                this.jiaoyan()
                return
            }
            let data ={
                id:item.id,
                user_id:this.userinfo.userid
            }
            await commentReplyLike(data)
            item.like = true
            item.like_num = item.like_num+1
        }
    }
}
</script>
<style lang="scss" scoped>
    .solutionOver {
        margin: 0 auto;
        overflow-x: auto;  
    }
    .appmain{
        padding-top: 2%;
    }
    .topmain{
        background: #fff;
        width: 75%;
        margin: 0 auto;
        padding-bottom: 3%;
    }
    .image{
        width: 302px;
        margin: 0 auto;
        margin-top: 9%;
    }
    .productinfo{
        width: 80%;
        margin: 0 auto;
        margin-top: 8%;
        .productname{
            font-family: SourceHanSansCN-Bold;
            font-size: 32px;
            font-weight: bold;
            color: #333333;
        }
        .detail{
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            color: #333333;
            height:60px;
            font-family: SourceHanSansCN-Regular;
        }
        .fankui{
            margin-top: 10%;
            width: 152px;
            border: 1px solid #0052D9;
            height: 48px;
            text-align: center;
            line-height: 48px;
            color: #0052D9;
            cursor:pointer;
            font-family: SourceHanSansCN-Regular;
            font-weight: 400;
        }
    }
    .menuslist{
        width: 80%;
        margin: 0 auto;
        .pinglun{
            .pltitle{
                font-size: 22px;
                font-weight: bold;
                color: #333333;
            }
            .plinput{
                margin-top: 20px;
                .plbtn{
                    width: 132px;
                    height: 48px;
                    background: #0052D9;
                    text-align: center;
                    line-height: 48px;
                    color: #fff;
                    margin-top: 10px;
                    cursor: pointer;
                }
            }
            .pllist{
                .allpltitle{
                    font-weight: bold;
                    color: #4E4D47;
                    .plnum{
                        color: rgba(78, 77, 71, 0.5);
                        margin-left: 10px;
                    }
                }
                .plitem{
                    display: flex;
                    margin-top: 15px;
                    .plimage{
                        margin-top: 5px;
                    }
                    .plright{
                        width: 100%;
                        margin-left: 10px;
                        .plname{
                            font-size: 14px;
                            font-weight: bold;
                            color: #4E4D47;
                        }
                        .plcontent{
                            margin-top: 10px;
                            font-size: 14px;
                            color: #4E4D47;
                        }
                        .ploption{
                            display: flex;
                            justify-content: space-between;
                            .pldate{
                                margin-top: 10px;
                                font-size: 13px;
                                color: rgba(78, 77, 71, 0.4);
                            }
                            .plicon{
                                cursor: pointer;
                                font-size: 16px;
                                color: rgba(78, 77, 71, 0.4);
                            }
                        }
                        .huifu{
                            margin-top: 10px;
                            .hfbtn{
                                width: 80px;
                                height: 30px;
                                border: 1px solid #0052D9;
                                text-align: center;
                                line-height: 30px;
                                margin-top: 10px;
                                color: #0052D9;
                                font-size: 14px;
                                cursor: pointer;
                            }
                        }
                    }
                    
                }
            }
        }
        .jishu{
            width: 90%;
            margin: 0 auto;
            margin-top: 20px;
            display: flex;
            .title{
                width: 30%;
                font-size: 16px;
                font-weight: bold;
                color: #333333;
            }
            .num{
                font-weight: 500;
            }
        }
        .anli{
            margin-top: 2%;
            .alimage{
                width: 100%;
            }
            .alinfo{
                width: 90%;
                margin: 0 auto;
                margin-top: 5%;
                .altitle{
                    width:100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    margin-bottom: 3%;
                    font-size: 16px;
                    font-weight: 550;                    
                }
                .aldate{
                    color: #6e6d6d;
                    font-size: 14px;
                    margin-bottom: 5%;
                }
            }
            
        }
        .jiejue{
            margin-top: 2%;
            .jjimage{
                width: 184px;
                margin: 0 auto;
            }
            .jjtitle{
                height: 25px;
                color: #333333;
                font-weight: 550;
                text-align: center;
            }
        }
    }
    @media screen and (max-width: 480px) {
        .menuslist{
            width: 100%;
        }
        .productinfo{
            .detail{
                height:auto !important;
            }
        }
    }
    @media screen and (max-width: 1280px) {
        .productinfo{
            .productname{
                font-size: 28px;
            }
            .detail{
                font-size: 16px;
            }
            .fankui{
                margin-top: 30%;
            }
        }
    }
</style>
<style scoped>
    /deep/ .el-tabs__nav-wrap::after {
        position: static !important;
    }
    /deep/ .el-tabs__header{
        width: 80%;
        margin: 0 auto;
    }
    /deep/ .el-tabs__item.is-active{
        color: #0052d9;
    }
    /deep/ .el-tabs__item:hover{
        color: #0052d9;
    }
    /deep/ .el-tabs__active-bar{
        background-color:#0052d9;
    }
    /deep/ img{
        width: 100%;
    }
    .pinglun >>> .el-textarea__inner{
        background: #F9F9F9;
    }
</style>